import React from 'react';
import { ButtonStyles } from './Button.styles';
const Button = ({ addon, addonPosition, branding, className, disabled, title, type = 'button', onClick, }) => {
    return (React.createElement(ButtonStyles.Button, { "data-theme": branding, className: className, disabled: disabled, iconPosition: addonPosition, type: type, onClick: onClick },
        React.createElement(ButtonStyles.HoverEffect, null),
        React.createElement(ButtonStyles.Inner, null,
            addon && addonPosition === 'left' && (React.createElement(ButtonStyles.Icon, { position: addonPosition }, addon)),
            React.createElement(ButtonStyles.Text, null, title),
            addon && addonPosition === 'right' && (React.createElement(ButtonStyles.Icon, { position: addonPosition }, addon)))));
};
const LinkButton = ({ addon, addonPosition, branding, className, onClick, href, target = '_self', title, download, }) => {
    return (React.createElement(ButtonStyles.Button, { "data-theme": branding, className: className, as: "a", href: href, target: target, download: download, onClick: onClick },
        React.createElement(ButtonStyles.HoverEffect, null),
        React.createElement(ButtonStyles.Inner, null,
            addon && addonPosition === 'left' && (React.createElement(ButtonStyles.Icon, { position: addonPosition }, addon)),
            React.createElement(ButtonStyles.Text, null, title),
            addon && addonPosition === 'right' && (React.createElement(ButtonStyles.Icon, { position: addonPosition }, addon)))));
};
export { LinkButton, Button as default };
